
import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import { JnService } from '@/services/jn.service';
import JnProgress from '@/views/common/Progress.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Mail } from '@/models/mail.model';
import { EmailTemplates } from '@/models/enums.model';
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import MetaInfo from 'vue-meta';

@Component({
  components: {
    JnProgress,
    ValidationObserver,
    ValidationProvider,
    StripeCheckout,
  },
  metaInfo(this: JnSottoscrizioneNewsletter): MetaInfo {
    return { title: 'JuraNews - Sottoscrizione Newsletter' };
  },
})
export default class JnSottoscrizioneNewsletter extends Vue {
  @Prop({ required: false })
  result?: string;

  identificativo = '';
  email = '';
  phoneNumber = '';

  alert = false;
  alertMessage = '';
  alertTimeout = 6000;
  loading = false;

  submitted = false;

  async submit() {
    this.loading = true;
    await JnService.sendMail(
      new Mail({
        to: process.env.VUE_APP_JURANEWS_MAIL!,
        template: EmailTemplates.SOTTOSCRIZIONE_NEWSLETTER,
        locals: {
          identificativo: this.identificativo,
          phoneNumber: this.phoneNumber,
          username: this.email,
        },
        attachments: [],
      })
    );
    this.submitted = true;
  }
}
